import React, {useState} from "react"
import { Col, Container, Row} from "react-bootstrap"
import SupportHeroIllustration from "../../static/images/support-hero-image.png"
import Cta from "../components/cta"
import Footer from "../components/footer"
import Layout from "../components/layout"
import NavBar from "../components/navBar"
import Seo from "../components/seo"
import Banner from "../components/banner"
import NewHero from "../components/hero/newHero";
import AppAccordion from "../components/AppAccordion";
import {faqsPageFaqs} from "../_shared/constants";

const Support = ({ location }) => {
    const [isSubmitTicket, setIsSubmitTicket] = useState(false)

    const supportPageFaqs = {
        sectionOne: faqsPageFaqs.sectionOne.filter((item) =>
            (
                item.question === 'What is Team Coaches?' ||
                item.question === 'Can I customize Team Coaches for my programs?' ||
                item.question === 'Can I integrate Team Coaches with other apps?'
            )
        ),

        sectionTwo: faqsPageFaqs.sectionTwo.filter((item) =>
            (item.question !== 'Do you offer customizations for your customers?')
        ),
    }

    return (
        <Layout isSupportPage={isSubmitTicket} onModalClose={() => setIsSubmitTicket(false)}>
            <Seo title="Team Coaches Support Page" />
            <NavBar location={location} />
            <Container fluid={true} className="px-0">
                <div className="my-5">
                    <NewHero
                        title={
                            <>
                                Customer Support
                            </>
                        }
                        isFluid={true}
                        description="Join our community-focused business for exceptional customer service. We value your feedback and offer tailored support, ensuring a mutually supportive environment. Together, we can grow and succeed."
                        altText="Get a Demo"
                        buttonText="Get a Demo"
                        image={SupportHeroIllustration}
                        demoButton={false}
                        descStyles={{
                            color: '#232B36',
                        }}
                        style={{
                            justifyContent: "space-between !important",
                        }}
                    />
                </div>
                <Banner isSupportPage={true} onClickSubmitTicket={() => setIsSubmitTicket(true)}/>
                <Row style={{ paddingBottom: '8em', paddingTop: '8em' }} className="mx-0">
                    <Col xs={12} md={10} className="mx-auto mb-5">
                        <h2 className="text-center text-md-start">Enterprise-grade Support, On-boarding, and Training</h2>
                    </Col>
                    <Col xs={12} md={10} className="mx-auto">
                        <Row>
                            <Col xs={12} md={4}>
                                <h4 className="mb-4 text-center text-md-start">Dedicated Support</h4>
                                <div className="support-types-description text-center text-md-start">
                                    Our specialists got you covered regardless of the type of your rare ready to provide you with precise solutions for your needs and request! Get in touch with us via email or request a meeting with our support team at any point.
                                </div>
                            </Col>
                            <Col xs={12} md={4}>
                                <h4 className="mb-4 mt-5 mt-md-0 text-center text-md-start">Customer On-boarding</h4>
                                <div  className="support-types-description text-center text-md-start">
                                    Make your first steps in the platform an easy and reward task with the help of our on-boarding specialists, ready to  help you with any questions you have during on-boarding and validates your setup when you’re ready for the go-live.
                                </div>
                            </Col>
                            <Col xs={12} md={4}>
                                <h4 className="mb-4 mt-5 mt-md-0 text-center text-md-start">Trainings</h4>
                                <div className="text-center text-md-start support-types-description">
                                    Our team of professionals will provide you with the necessary trainings to help you during setup and training of your team.
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="faqs-container m-0">
                    <Col className="text-center col-12 mt-5 mb-2">
                        <h2 className="class-header">Frequently Asked Questions </h2>
                    </Col>
                    <Col className="col-12 col-md-10 col-lg-10 mx-auto pb-5">
                        <Row className="m-0">
                            <Col className="col-12 col-md-5 col-lg-5 m-0">
                                <AppAccordion items={supportPageFaqs.sectionOne}/>
                            </Col>
                            <Col className="col-1 hide-on-small-screen" />
                            <Col className="col-12 col-md-5 col-lg-5 m-0">
                                <AppAccordion items={supportPageFaqs.sectionTwo}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Cta
                    backgroundColor="back-blue"
                    buttonColor="blue"
                    title="Interested in Learning more?"
                    buttonText="Let's Talk"
                />
                <Footer />
            </Container>
        </Layout>
    )
}

export default Support
